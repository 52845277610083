export enum FeatureFlags {
  AccountManagersEmployeesRelationshipsChart = 'ACCOUNT_MANAGERS_EMPLOYEES_RELATIONSHIPS_CHART',
  BusinessOrgMapping = 'BUSINESS_ORG_MAPPING',
  CarrierAppetiteCommunityTab = 'CARRIER_APPETITE_COMMUNITY_TAB',
  CarrierPdfReportLink = 'CARRIER_PDF_REPORT_LINK',
  ChatAIButton = 'CHAT_AI_BUTTON',
  ClientTagsFilter = 'CLIENT_TAGS_FILTER',
  ClientsAndPolicies = 'CLIENTS_AND_POLICIES',
  CompanySearchCountrySelect = 'COMPANY_SEARCH_COUNTRY_SELECT',
  CrossSellProspects = 'CROSS_SELL_PROSPECTS',
  DefaultBizOrgFilterValues = 'DEFAULT_BUSINESS_ORG_FILTERS',
  Documents = 'DOCUMENTS',
  ExecutiveOverview = 'EXECUTIVE_OVERVIEW',
  ExecutiveOverviewEntityRows = 'EXECUTIVE_OVERVIEW_ENTITY_ROWS',
  ExportHide = 'EXPORT_HIDE',
  HomePageProducerQuickLinks = 'HOME_PAGE_PRODUCER_QUICK_LINKS',
  Intermediaries = 'INTERMEDIARIES',
  KPI = 'KPI_PAGE',
  LifetimeValueSection = 'LIFETIME_VALUE_SECTION',
  LocationReportHazardHub = 'LOCATION_REPORT_HAZARD_HUB',
  LossRuns = 'LOSS_RUNS',
  NewBusinessProducers = 'NEW_BUSINESS_PRODUCERS',
  NewBusinessProducersSetGoal = 'NEW_BUSINESS_PRODUCERS_SET_GOAL',
  O360Score = 'O360_SCORE',
  Opportunities = 'OPPORTUNITIES',
  OpportunitiesLocationSearch = 'OPPORTUNITIES_LOCATION_SEARCH',
  OpportunitiesProspects = 'OPPORTUNITIES_PROSPECTS',
  PolicyStatusFilter = 'POLICY_STATUS_FILTER',
  PolicyTagsFilter = 'POLICY_TAGS_FILTER',
  PolicySourceCodes = 'POLICY_SOURCE_CODES',
  PremiumInflowOutflow = 'PREMIUM_INFLOW_OUTFLOW',
  ProducerPortal = 'PRODUCER_PORTAL',
  ProducerViewWithCarrierAppetite = 'PRODUCER_VIEW_WITH_CARRIER_APPETITE',
  RevealPlugin = 'REVEAL_PLUGIN',
  Renewals = 'RENEWALS',
  RenewalStage = 'RENEWAL_STAGE',
  ReportStudioDataMartsSource = 'REPORT_STUDIO_DATA_MARTS_SOURCE',
  RetentionPage = 'RETENTION_PAGE',
  UnisonTenant = 'UNISON_TENANT',
  UserRoleFilter = 'USER_ROLE_FILTER',
}
