import {useCallback} from 'react';
import {useShallow} from 'zustand/react/shallow';

import {FeatureFlag} from 'core/types';
import {EnnablRoleType} from 'modules/command/api/types';
import {AppPermissionId} from 'modules/decisionsNext/api/types';

import {FeatureFlags} from 'core/constants/featureFlags';
import {AuthState, useAuthStore} from 'core/stores/auth';
import {IS_DEV_ENV} from 'config';
import {fetchLocalFeatureFlags} from 'modules/modifier';

export const useUserInfo = () => useAuthStore(state => state.userInfo) as Exclude<AuthState['userInfo'], undefined>;

export const useAppPermissions = () => {
  const appPermissions = useAuthStore(state => state.userInfo?.permissions);

  return {
    hasPermission: useCallback(
      (permissions: AppPermissionId | AppPermissionId[]) => {
        const permissionsArr = Array.isArray(permissions) ? permissions : [permissions];
        return permissionsArr.every(permission => appPermissions?.includes(permission));
      },
      [appPermissions]
    ),
  };
};

export const useOnlyReportStudioPermission = () => {
  const {hasPermission} = useAppPermissions();
  return hasPermission(AppPermissionId.OnlyReportStudio);
};

const checkFeatureFlagInternal = (tenantId: string, name: FeatureFlag['name'], flag?: FeatureFlag) => {
  if (IS_DEV_ENV) {
    const {[name]: modified} = fetchLocalFeatureFlags();

    if (modified !== undefined) {
      return modified;
    }

    return flag?.enabledOnDev !== false;
  }

  if (!flag) {
    return false;
  }

  if (tenantId && flag.enabled) {
    if (flag.activeForTenants) {
      return flag.activeForTenants.includes(tenantId);
    }

    if (flag.inactiveForTenants?.includes(tenantId)) {
      return false;
    }
  }

  return flag.enabled;
};

export const useFeatureFlag = (name: FeatureFlag['name']): boolean => {
  const {tenantId} = useUserInfo();
  const flag = useAuthStore(useShallow(state => state.flags?.find(flag => flag.name === name)));

  return checkFeatureFlagInternal(tenantId, name, flag);
};

/**
 * @deprecated Non-reactive getter, use only in actions
 */
export const checkFeatureFlag = (name: FeatureFlag['name']) => {
  const tenantId = useAuthStore.getState().userInfo?.tenantId as string;
  const flag = useAuthStore.getState().flags?.find(flag => flag.name === name);

  return checkFeatureFlagInternal(tenantId, name, flag);
};

export const useTenantFeatureFlag = (name: FeatureFlag['name']): boolean => {
  const {tenantId} = useUserInfo();
  const flag = useAuthStore(useShallow(state => state.flags?.find(flag => flag.name === name)));

  if (!flag) {
    return false;
  }

  return flag?.meta?.includes(tenantId) ?? false;
};

export const useIsExportHidden = () => {
  const {hasPermission} = useAppPermissions();

  return !hasPermission(AppPermissionId.ExportDecisions);
};

const useIsRoleType = (roleType: EnnablRoleType) =>
  useAuthStore(useShallow(state => state.userInfo?.roleType === roleType));

/** Admin with Admin/Custom roles */
export const useIsAdmin = () => {
  const isAdminRole = useIsRoleType(EnnablRoleType.Admin);
  const isCustomRole = useIsRoleType(EnnablRoleType.Custom);

  return isAdminRole || isCustomRole;
};

export const useIsAdminOnly = () => useIsRoleType(EnnablRoleType.Admin);
export const useIsProducer = () => useIsRoleType(EnnablRoleType.Producer);
export const useIsAccountManager = () => useIsRoleType(EnnablRoleType.AccountManager);

// TODO: Replace with useIsProducer after Growth is released to all tenants
export const useIsGrowthProducer = () => {
  const withGrowth = useFeatureFlag(FeatureFlags.ProducerPortal);
  const isProducer = useIsProducer();

  return withGrowth && isProducer;
};

export const useWithEnnablAI = () => {
  const withEnnablAIButton = useFeatureFlag(FeatureFlags.ChatAIButton);
  const isAdmin = useIsAdminOnly();

  const isAIEnabled = isAdmin || withEnnablAIButton;

  return {isAIEnabled, isAdmin};
};
